import React, { Suspense } from "react";
import { useParams } from "next/navigation";
import { IoClose } from "react-icons/io5";
import isEmpty from "lodash/isEmpty";

// components
import AuthHeaderLogo from "./AuthHeaderLogo";
import AuthHeaderNavigation from "./AuthHeaderNavigation";
import HeaderRegionSelector from "@/components/HeaderRegionSelector";
import HirerHeaderSearch from "@/components/HirerHeaderSearch";
import LogoutIcon from "@/components/icons/LogoutIcon";

const LandingSocials = React.lazy(() => import("@/components/LandingSocials"));

// elements
import Button from "@/shared/Button";

// helpers
import useUserProfile from "@/hooks/useUserProfile";
import { getHeaderNavigationLinks, getHirerInfoLinks, accountLinks, supportsLinks } from "../helpers";

// interfaces
import { IAuthHeaderMobile, IAuthHeaderNavigationItem } from "../IAuthHeader";

// assets
import GoSearchIcon from "@/components/icons/GoSearchIcon";
import BookDemoIcon from "@/components/icons/BookDemoIcon";

import styles from "@/assets/styles/components/Header/AuthHeader/index.module.scss";

export const WERI_HEADER_NAVIGATION = [
  {
    label: "Search",
    value: "/search-results",
    icon: <GoSearchIcon />
  }
];

function AuthHeaderMobile({ onClose, onLogout, pathname, unreadMessages }: IAuthHeaderMobile) {
  const { lang } = useParams();
  const { isAuthorized, data } = useUserProfile();

  const HEADER_NAVIGATION_LINKS = getHeaderNavigationLinks(true, lang as string, isAuthorized);
  const HEADER_NAVIGATION_LINKS_HIRER_INFO = getHirerInfoLinks(unreadMessages)

  const isLandlordPage = pathname.includes("/landlord");
  const bookDemoLink = isLandlordPage ? "/book-a-demo?type=1" : "/book-a-demo";

  const blockClassName = styles["locl-auth-header-mobile__block"];

  // renders
  const renderDivider = () => (
    <div className={styles["locl-auth-header-mobile__divider"]} />
  )

  return (
    <div className={styles["locl-auth-header-mobile"]} data-testid="locl-auth-header-mobile">
      <div className={styles["locl-auth-header-mobile__logo-block"]}>
        <AuthHeaderLogo pathname={pathname} />
        <span
          className={styles["locl-auth-header-mobile__logo-block__close-icon"]}
          data-testid="locl-auth-header-mobile-close-icon"
          onClick={onClose}
        >
          <IoClose />
        </span>
      </div>
      {process.env.NEXT_PUBLIC_APP_WL_CODE !== "WERI" && (
        <>
          <div className={blockClassName}>
            <HirerHeaderSearch type="secondary" fullWidth />
          </div>
          <div className={blockClassName}>
            <HeaderRegionSelector onRegionChange={onClose} isAuthHeader />
          </div>
        </>
      )}
      <div className={blockClassName}>
        {process.env.NEXT_PUBLIC_APP_WL_CODE === "WERI" ? (
          <AuthHeaderNavigation
            navigationUrls={[
              {
                ...WERI_HEADER_NAVIGATION[0],
                label: "Start new search"
              }
            ]}
            onLinkClick={onClose}
          />
        ) : (
          <AuthHeaderNavigation
            navigationUrls={HEADER_NAVIGATION_LINKS as IAuthHeaderNavigationItem[]}
            onLinkClick={onClose}
          />
        )}
      </div>
      {
        isAuthorized && (<>
          {renderDivider()}
          <AuthHeaderNavigation
            navigationUrls={accountLinks as IAuthHeaderNavigationItem[]}
            onLinkClick={onClose}
          />
          { renderDivider()}
          <AuthHeaderNavigation
            navigationUrls={HEADER_NAVIGATION_LINKS_HIRER_INFO as IAuthHeaderNavigationItem[]}
            onLinkClick={onClose}
          />
          { renderDivider()}
          <AuthHeaderNavigation
            navigationUrls={supportsLinks as IAuthHeaderNavigationItem[]}
            onLinkClick={onClose}
          />
        </>
         
        )
      }
      <div className={blockClassName}>
        {
          !!(!isAuthorized || isEmpty(data)) && (<>
            <Button
              fullWidth
              to="/login"
              data-testid="locl-auth-header-mobile-button-auth"
            >
              Log in
            </Button>
            <Button
              to="/registration"
              fullWidth
              danger
              data-testid="locl-auth-header-mobile-button-register"
            >
              Sign up, it’s free!
            </Button>
          </>)
        }
        <Button
          to={bookDemoLink}
          type="secondary"
          success
          icon={<BookDemoIcon />}
          fullWidth
        >
          Book a demo
        </Button>
        {
          (isAuthorized && !isEmpty(data)) && (
            <Button
              type="secondary"
              fullWidth
              icon={<LogoutIcon/>}
              className={styles["locl-auth-header-mobile__logout-button"]}
              onClick={(e: React.MouseEvent<HTMLElement>) => onLogout(e)}
              data-testid="locl-auth-header-mobile-button-logout"
            >
              Log Out
            </Button>
          )
        }
        {process.env.NEXT_PUBLIC_APP_WL_CODE === "LOCL" && (
          <>
            <Suspense fallback="...">
              <LandingSocials />
            </Suspense>
            <p className={styles["locl-auth-header-mobile__copyright"]}>
              {new Date().getFullYear()} location:live all rights reserved
            </p>
          </>
        )}
      </div>
    </div>
  );
}

export default AuthHeaderMobile;
