"use client";

import React, { MouseEvent, useEffect, useState } from "react";
import { usePathname, useParams } from "next/navigation";
import isEmpty from "lodash/isEmpty";
import dynamic from "next/dynamic";

// interfaces
import { IAuthHeaderNavigationItem } from "./IAuthHeader";

// components
import Button from "@/shared/Button";
import BaseHeader from "@/components/Header/BaseHeader";
import AuthHeaderLogo from "./components/AuthHeaderLogo";
const AuthHeaderNavigation = dynamic(() => import("./components/AuthHeaderNavigation"), { ssr: false, loading: () => <span>Loading...</span> });
const AuthHeaderMobile = dynamic(() => import("./components/AuthHeaderMobile"), { ssr: false, loading: () => <span>Loading...</span> });
import HeaderAuthPopover from "@/components/HeaderAuthPopover";
const HeaderRegionSelector = dynamic(() => import("@/components/HeaderRegionSelector"), { ssr: false, loading: () => <span>Loading...</span> });
const HeaderProfileDropdown = dynamic(() => import("@/components/HeaderProfileDropdown"), { ssr: false, loading: () => <span>Loading...</span> });
const HirerHeaderSearch = dynamic(() => import("@/components/HirerHeaderSearch"), { ssr: false, loading: () => <span>Loading...</span> });

// helpers
import { useDimension } from "@/hooks/useDimensions";
import {
  getHeaderNavigationLinks,
  SHOW_HEADER_THRESHOLD,
  PAGES_WITH_STICKY_HEADER
} from "./helpers";
import { removeLangFromPathname } from "@/helpers/removeLangFromPathname";
import useUserProfile from "@/hooks/useUserProfile";
import account from "@/api/account";
import useRouter from "@/hooks/useRouter";
import useUnreadMessages from "@/hooks/useUnreadMessages";
import notification from "@/components/notifications";

// assets
import styles from "@/assets/styles/components/Header/AuthHeader/index.module.scss";

const AuthHeader = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showHeader, setShowHeader] = useState(true);

  const [width] = useDimension();
  const { isAuthorized, data } = useUserProfile();
  const { push } = useRouter();

  const isTablet = width <= 1200;

  const pathname = usePathname();
  const { lang } = useParams();
  const currentPath = removeLangFromPathname(pathname);

  const withList = pathname.includes("/lolive");
  const showSearch = pathname.replace(lang as string, "") !== "/";

  const HEADER_NAVIGATION_LINKS = getHeaderNavigationLinks(withList, lang as string, isAuthorized);

  const [unreadMessages] = useUnreadMessages({
    onNudgeMessage: (message: string) => notification({
      type: "info",
      placement: "bottomLeft",
      duration: 5,
      description: message,
    })
  });

  // methods
  const handleScroll = () => {
    if (PAGES_WITH_STICKY_HEADER.includes(pathname)) {
      setShowHeader(true);

      return;
    }

    // Show header if scrolling up or scroll position is less than the threshold
    setShowHeader(window.scrollY < SHOW_HEADER_THRESHOLD || scrollPosition > window.scrollY);

    // Update scroll position
    setScrollPosition(window.scrollY);
  };

  const logoutUser = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    await account.logOut();
  };

  const handleLogin = (userType: string) => {
    if (userType === "hirer") {
      push("/go-search");

      return;
    }

    push("/locations");
  }

  // renders
  const renderHeaderProfile = () => (
    (isAuthorized && !isEmpty(data))
      ? <HeaderProfileDropdown user={data} onLogout={logoutUser} unreadMessages={unreadMessages} isLanding key="header-profile"/>
      : (
        <div className={styles["locl-auth-header__auth-buttons"]}>
          <HeaderAuthPopover onSubmit={handleLogin} key="header-auth-popover"/>
          <Button
            danger
            to="/registration"
          >
            Sign up, it’s free!
          </Button>
        </div>
      )
  );

  // effects
  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <BaseHeader
      className={styles["locl-auth-header"]}
      data-testid="locl-auth-header"
      renderDrawerContent={(onClose: () => void) => (
        <AuthHeaderMobile
          onClose={onClose}
          onLogout={logoutUser}
          pathname={pathname}
          unreadMessages={unreadMessages}
        />
      )}
      style={{
        transform: `translateY(${showHeader ? 0 : -72}px)`
      }}
    >
      <BaseHeader.Logo>
        <AuthHeaderLogo pathname={pathname} />
      </BaseHeader.Logo>
      {!isTablet && (
        <div className={styles["locl-auth-header__navigation-block"]} data-testid="locl-auth-header-navigation-block-desk">
          <AuthHeaderNavigation navigationUrls={HEADER_NAVIGATION_LINKS as IAuthHeaderNavigationItem[]}/>
          {showSearch && (
            <div className={styles["locl-auth-header__search-block"]} data-testid="locl-auth-header-search">
              <HirerHeaderSearch type="secondary" fullWidth />
            </div>
          )}
        </div>
      )}
      <BaseHeader.Profile>
        {!isTablet && (
          <>
            { process.env.NEXT_PUBLIC_APP_WL_CODE === "LOCL" && <HeaderRegionSelector isAuthHeader /> }
            { !isTablet && renderHeaderProfile()}
          </>
          
        )}
      </BaseHeader.Profile>
      {
        currentPath === "/" && process.env.NEXT_PUBLIC_APP_WL_CODE === "LOCL" && (
          <BaseHeader.MobileRightContent>
            <Button
              to="/lolive"
              danger
              onClick={e => {
                if (isAuthorized) {
                  e.preventDefault();
                  push("/go-search");
                }
              }}
            >
              LO:LIVE
            </Button>
          </BaseHeader.MobileRightContent>
        )
      }
    </BaseHeader>
  );
};

export default AuthHeader;
