import React from "react";
import { usePathname } from "next/navigation";
import classNames from "classnames";

// components
import Link from "@/components/Link";

// helpers
import { removeLangFromPathname } from "@/helpers/removeLangFromPathname";

// interfaces
import { IAuthHeaderNavigation, IAuthHeaderNavigationItem } from "../IAuthHeader";

// assets
import styles from "@/assets/styles/components/Header/AuthHeader/index.module.scss";

const AuthHeaderNavigation = (props: IAuthHeaderNavigation) => {
  const { navigationUrls, onLinkClick } = props;

  const pathname = usePathname();

  const currentUrl = removeLangFromPathname(pathname);

  const renderNavigationItem = (item: IAuthHeaderNavigationItem, index: number) => {
    const itemClassName = classNames(styles["locl-auth-header__nav-item"], {
      [styles["locl-auth-header__nav-item__active"]]: currentUrl === item.value
    });

    return (
      <li className={styles["locl-auth-header__nav-list-item"]} key={`locl-auth-header-nav-item-${index}`}>
        <Link
          href={item.value}
          className={itemClassName}
          onClick={onLinkClick}
        >
          {item.icon && <span className={styles["locl-auth-header__nav-item__icon"]}>{item.icon}</span>}
          <span className={styles["locl-auth-header__nav-item__label"]}>{item.label}</span>
          { !!item.badge && (
            <span className={classNames(
              styles["locl-auth-header__nav-item__badge"],
            )}>
              { +item.badge > 99 ? "+99" : item.badge }
            </span>
          ) }
        </Link>
      </li>
    );
  };

  return (
    <nav data-testid="locl-auth-header-nav">
      <ul className={styles["locl-auth-header__nav-list"]}>
        {navigationUrls.map(renderNavigationItem)}
      </ul>
    </nav>
  );
};

export default AuthHeaderNavigation;
