"use client";

//helpers
import account from "@/api/query/account";

// interfaces
import { IUserProfile, IHirerPermissions } from "@/types/api/UserProfile";
import { IUseQueryResult } from "@/types/api/UseQueryResult";


export interface IUseUserProfile extends Omit<IUseQueryResult<IUserProfile>, "isLoading"> {
  tokens: {
    accessToken: string | undefined;
    refreshToken: string | undefined;
  };
  isAuthorized: boolean;
  isLoading: boolean;
  permissions: {
    hirer: IHirerPermissions;
  };
}

export const HIRER_COMPANY_ROLES = {
  manager: 1,
  staff: 2,
};

export const mapHirerPermissions = (hirer?: IUserProfile): IHirerPermissions => {
  const hirerPermissions: IHirerPermissions = {};

  if (!hirer) {
    return hirerPermissions;
  }

  if (hirer.accessLevels && hirer.accessLevels.find(item => item.code === "Manage booking")) {
    hirerPermissions.canManageBookings = true;
  }

  if (hirer.accessLevels && hirer.accessLevels.find(item => item.code === "Access booking history")) {
    hirerPermissions.canAccessBookingsHistory = true;
  }

  if (hirer.companyRole === HIRER_COMPANY_ROLES.manager) {
    hirerPermissions.canAddTeamMember = true;
    hirerPermissions.canAccessBookingsHistory = true;
    hirerPermissions.canManageBookings = true;
  }

  return hirerPermissions;
};

export default function useUserProfile(args?: { initIsAuthorized?: boolean }): IUseUserProfile {
  const initIsAuthorized = args?.initIsAuthorized ?? false;

  const { data: isAuthenticated, isLoading } = account.useIsAuthenticated();
  const { data, ...rest } = account.useProfileMe({ config: { enabled: !!isAuthenticated?.accessToken } });

  return {
    ...rest,
    data,
    tokens: {
      accessToken: isAuthenticated?.accessToken,
      refreshToken: isAuthenticated?.refreshToken,
    },
    isAuthorized: !!isAuthenticated?.accessToken || initIsAuthorized,
    isLoading: !!isLoading || (!!isAuthenticated && rest.isLoading),
    permissions: { hirer: mapHirerPermissions(data) }
  };
}
