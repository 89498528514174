import React, { MouseEvent } from "react";
import classNames from "classnames";
import { usePathname } from "next/navigation";

// components
import Button from "@/shared/Button";
import AuthHeaderLogo from "../components/AuthHeaderLogo";
import BaseHeader from "../../BaseHeader";
import AuthHeaderMobile from "../components/AuthHeaderMobile";
import AuthHeaderNavigation from "../components/AuthHeaderNavigation";
import HeaderAuthPopover from "@/components/HeaderAuthPopover";
import HeaderProfileDropdown from "@/components/HeaderProfileDropdown";

// helpers
import { useDimension } from "@/hooks/useDimensions";
import useUserProfile from "@/hooks/useUserProfile";
import account from "@/api/account";
import useRouter from "@/hooks/useRouter";
import useUnreadMessages from "@/hooks/useUnreadMessages";
import notification from "@/components/notifications";

// assets
import BookDemoIcon from "@/components/icons/BookDemoIcon";
import GoSearchIcon from "@/components/icons/GoSearchIcon";
import styles from "@/assets/styles/components/Header/AuthHeader/index.module.scss";


export const WERI_HEADER_NAVIGATION = [
  {
    label: "Search",
    value: "/search-results",
    icon: <GoSearchIcon />
  }
];

function WERIAuthHeader() {
  const [width] = useDimension();
  const isTablet = width && width < 1200;

  const pathname = usePathname();
  const { push } = useRouter();
  const { isAuthorized, data } = useUserProfile();

  const [unreadMessages] = useUnreadMessages({
    onNudgeMessage: (message: string) => notification({
      type: "info",
      placement: "bottomLeft",
      duration: 5,
      description: message,
    })
  });

  const logoutUser = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    await account.logOut();
  };

  const handleLogin = (userType: string) => {
    if (userType === "hirer") {
      push("/search-results");

      return;
    }

    push("/locations")
  }

  // renders
  const renderHeaderProfile = () => (
    (isAuthorized && data)
      ? <HeaderProfileDropdown user={data} onLogout={logoutUser} key="header-profile"/>
      : (<>
        <div className={styles["locl-auth-header__auth-buttons"]}>
          <HeaderAuthPopover onSubmit={handleLogin} key="header-auth-popover"/>
          <Button
            danger
            to="/registration"
          >
            Sign up, it’s free!
          </Button>
        </div>
      </>)
  );

  return (
    <BaseHeader
      className={styles["locl-auth-header"]}
      renderDrawerContent={(onClose: () => void) => (
        <AuthHeaderMobile onClose={onClose} onLogout={logoutUser} pathname={pathname} unreadMessages={unreadMessages} />
      )}
    >
      <BaseHeader.Logo>
        <AuthHeaderLogo pathname={pathname} />
      </BaseHeader.Logo>
      <div className={classNames(styles["locl-auth-header__navigation-block"], styles["locl-auth-header__navigation-block_offset"])}>
        { !isTablet && pathname !== "/" && (<>
          <AuthHeaderNavigation
            navigationUrls={WERI_HEADER_NAVIGATION}
          />
        </>) }
      </div>
      <BaseHeader.Profile>
        <Button to="/book-a-demo" type="secondary" success icon={<BookDemoIcon />}>Book a demo</Button>
        { !isTablet && renderHeaderProfile() }
      </BaseHeader.Profile>
    </BaseHeader>
  );
}

export default WERIAuthHeader;
