import React, { Suspense } from "react";
import Image from "next/image";

// components
import Link from "@/components/Link";

// helpers
import LOCALIZATION_REGEX from "@/globals/localization.regex";

// assets
const LoCationLogoIcon = React.lazy(() => import("@/components/icons/SupportUkraine/LoCationLogoIcon"));
const LoLiveLogoIcon = React.lazy(() => import("@/components/icons/SupportUkraine/LoLiveLogoIcon"));
import weriLogo from "@/assets/images/landing/logo/weri-logo.png";
import styles from "@/assets/styles/components/Header/AuthHeader/index.module.scss";


const AuthHeaderLogo = ({ pathname }: { pathname: string; }) => {
  const clearPathname = pathname.replace(LOCALIZATION_REGEX, "/");

  const logoClassName = styles["locl-auth-header__logo"];
  const logoImgClassName = styles["locl-auth-header__logo-img"];

  const renderHeaderLogo = () => {
    switch (true) {
      case process.env.NEXT_PUBLIC_APP_WL_CODE === "WERI":
        return (
          <Link href="/" className={styles["locl-auth-header__logo_weri"]} data-testid="locl-auth-header-logo-weri">
            <Image
              width={125}
              height={68}
              src={weriLogo}
              alt="App Logo"
              className={styles["locl-auth-header__logo_weri-image"]}
            />
          </Link>
        );
      case ["/", "/blog", "/collections", "/landlord"].includes(clearPathname):
        return (
          <Link href="/" className={logoClassName} data-testid="locl-auth-header-logo">
            <Suspense fallback="...">
              <LoCationLogoIcon className={logoImgClassName} />
            </Suspense>
          </Link>
        );
      case pathname.includes("/bookingterms"):
        return (
          <Link href="/" className={logoClassName} data-testid="locl-auth-header-logo-bookingterms">
            <Suspense fallback="...">
              <LoLiveLogoIcon className={logoImgClassName} />
            </Suspense>
          </Link>
        );
      default:
        return (
          <Link
            href={["/lolive", "/book-a-demo"].includes(clearPathname) ? "/lolive" : "/"}
            className={logoClassName}
            data-testid="locl-auth-header-logo-lolive"
          >
            <Suspense fallback="...">
              <LoLiveLogoIcon className={logoImgClassName} />
            </Suspense>
          </Link>
        );
    }
  };

  return <React.Suspense fallback="...">{renderHeaderLogo()}</React.Suspense>;
};

export default AuthHeaderLogo;
