"use client";

import React, { ReactNode } from "react";
import dynamic from "next/dynamic";

// components
import AuthHeader from "@/components/Header/AuthHeader";
const LandingFooter = dynamic(() => import("@/components/Footer/LandingFooter"), { ssr: false });
const AnnouncementBanner = dynamic(() => import("@/components/AnnouncementBanner"), { ssr: false });

// helpers
import landingPage from "@/api/query/landing-page";

// assets
import styles from "../assets/styles/layouts/base-layout.module.scss";

export interface BaseLayoutProps { children: ReactNode; pathname?: string | null; }

function BaseLayout({ children, pathname }: BaseLayoutProps) {
  const { data } = landingPage.useAnnouncementBanner();

  return (
    <div className={styles["locl-base-layout"]}>
      { pathname?.includes("/lolive") && !!data?.length && <AnnouncementBanner/> }
      <AuthHeader />
      <main className={styles["locl-base-layout__content"]}>{children}</main>
      <LandingFooter />
    </div>
  );
}

function BaseLayoutSimple({ children }: BaseLayoutProps) {
  return (
    <div className={styles["locl-base-layout"]}>
      <main className={styles["locl-base-layout__content"]}>{children}</main>
    </div>
  );
}

export default process.env.NEXT_PUBLIC_IS_OPS_DASHBOARD ? BaseLayoutSimple : BaseLayout;
