import { useFetch } from "./reactQuery";
import API_ROUTES from "@/api/routes.constant";
import account from "@/api/query/account";


interface IAnnouncementBanner {
  id: number;
  bannerText: string;
}

export default {
  useAnnouncementBanner: () => {
    const { data: isAuthenticated } = account.useIsAuthenticated();

    return useFetch<IAnnouncementBanner[]>(
      API_ROUTES.landing.announcementBanner,
      {},
      { enabled: !!isAuthenticated?.accessToken && process.env.NEXT_PUBLIC_APP_WL_CODE === "LOCL" }
    );
  },
}
